
.usermenu.dropdown .dropdown-header {
  font-size: inherit;
  color: #1d2d35;
  font-weight: 600;
}


.dropdown-divider {
  border-top: 1px solid #e9ecef;
  
}
[data-dark-mode] .dropdown-divider {
  border-top: 1px solid #343a40;
  
}

.dropdown-menu {
  margin-top:5px;
  border-radius:0px !important;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 4px;
  box-shadow: 0 2px 20px rgba(5,5,5,.08);

}


.round-dropdown {
  margin-top:     6px;
  border-radius:  25px; /* make the button round */
  height:         25px;
  width:          25px;
  line-height:    15px; /* vertically center the text */
  font-size:      15px; /* make the letter big */
  text-align: center; /* center the text */
  padding: 0; /* remove padding */

  background-color: #1d2d35;
  border-color: #1d2d35;
}

.round-dropdown.btn.show {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.round-dropdown:hover{
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.navbar .navbuttons #signin div {
    border-radius: 25px;
    width: 80px;
    height: 26px;
    line-height: 26px;
    font-size: 0.8rem;
    background: linear-gradient(90deg, #0184a7, #ca5eed);
    color: #dee2e6;
}



[data-dark-mode] .dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 2px 20px rgba(250,250,250,.08);

  color: #dee2e6;
  background-color: rgba(33, 37, 41, 0.95);
  border: 1px solid #343a40;
}


[data-dark-mode] .round-dropdown:hover,
[data-dark-mode] .round-dropdown.btn.show ,
[data-dark-mode] .round-dropdown {
  color: #1d2d35;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

[data-dark-mode] .usermenu.dropdown .dropdown-item:hover{
  background:  #1b1f22;

}
[data-dark-mode] .usermenu.dropdown .dropdown-header {
  font-size: inherit;
  color: #dee2e6;
  font-weight: 600;
}


