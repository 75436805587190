.custom-toggle {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.custom-toggle-label::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.custom-toggle:checked + .custom-toggle-label {
  background: #4caf50;
}

.custom-toggle:checked + .custom-toggle-label::after {
  transform: translateX(26px);
}

