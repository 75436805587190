
.novem-jumbo{
  position:relative;
}
.novem-jumbo .novem-logo-tbg{
  filter: blur(5px);
  position:absolute;
  top:0px;
  left:0px;
  user-select: none;
  opacity:0.8;
  width:100%;
}

.body.home .productlead.section{
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.novem-jumbo .novem-logo-top{
  mix-blend-mode: overlay;
  width: 70%;
  margin:auto;
  margin-bottom: 0.5em;
  opacity: 0.6;
}

.novem-logo {
  position: absolute;
  width: 650px;
  height: 650px;
  left: -295px;
  mix-blend-mode: overlay;
  top:-335px;
}
.novem-logo .lit{
  fill:none;
  stroke: #dee2e6;
  stroke-width: 2px;
}


.novem-bars{
  z-index: -10;
  position: absolute;
  opacity: 0.6;
  mix-blend-mode: overlay;
  left:0px;
  top: 0px;
  width:100%;
  min-height:300px;
  height:300px;
}

.novem-bars svg{
  position: absolute;
  top:2rem;
  left:0px;
  opacity: 0.3;
  mix-blend-mode: overlay;
}


.novem-shade{
  z-index: -10;
  position: absolute;
  left:0px;
  top: 0px;
  width:100%;
  height:500px;
  background-color: rgba(33, 37, 41, 0.95);
  //background-color: #1f1f1f;
  //background-color: $white;
}



.fp--first--row {
  margin-top:-100px;
}

.fp--browser--hold {
  position:relative;
  min-height: 400px;
}

.fp--browser {
  position:absolute;
  right: 1rem;
  height: 400px;
  width: 600px;
  top: -30px;
  border-radius: 8px;
  background: linear-gradient(HSLA(0,0%,100%,.4),HSLA(0,0%,100%,.3) 25%,RGBA(246,249,252,.3) 50%,#f6f9fc 60%);
  box-shadow: inset 0 1px 1px 0 HSL(0deg  0% 100% / 10%), 0 50px 100px -20px RGB(50 50 93 / 25%), 0 30px 60px -30px RGB(0 0 0 / 30%);


  display: grid;
  grid-auto-rows: 5% 88%;
  --gutter: 30px;
  gap: 24px;
  padding: var(--gutter);
  font-size: 11px;
  font-weight: 300;
  letter-spacing: .2px;
  line-height: 14px;
  color: #425466;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fp--ex1--container--header{
  border-top: 3px solid;
  border-image-source: linear-gradient(90deg, #1f1f1f, #0184a7 50%, #ca5eed);
  border-image-slice: 1;
}

.fp--ex1--url--hold{
  width: 100%;
  display: grid;
  grid: auto-flow dense / 84px 456px 1fr;
}

.fp--ex1--url--nav{
  height: 25px;
  width: 70px;
  background: #fcfcfc;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px RGB(6 24 44 / 20%);
  line-height:25px;
  padding-left: 10px;
  height: 25px;
  color: black;
  font-weight: 400;
  display:grid;
  grid: auto-flow dense / 20px 20px 20px;

}

.fp--ex1--url--nav--icon{
  float:left;
  width: 15px;
}

.fp--ex1--url .fp--ex1--url--nav--icon{
  color: #59a14f;
  margin-left: 5px;
  width: 13px;
  margin-top: -2px;
}


.fp--ex1--url{
  background: #fcfcfc;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px RGB(6 24 44 / 20%);
  line-height:25px;
  padding-left: 0px;
  height: 25px;
  color: black;
  font-weight: 400;
}

.fp--ex1--container{
  background:white;

  border-left:    0.5px solid #1f1f1f;
  border-right:   0.5px solid #1f1f1f;
  border-bottom:  0.5px solid #1f1f1f;
  width: 100%;
  height: calc(100% - var(3px));
  position: relative;
  background: #fcfcfc;
  /*
  filter: drop-shadow(0 0 0.8rem #aaa);
  background: #fff;
  */
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px RGB(6 24 44 / 20%);
}

.fp--ex1--container--chart{
  margin:20px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 84%;
}

.fp--ex1--container--chart .c-qNGgN .tick text{
  color:black;
  font-size: 8px;
}
.fp--ex1--container--chart .c-qNGgN .ns-override .caption--textbox{
  margin-top: -10px;
  font-size: 6px;
}

.fp--command--demo {
  border: 1px solid #343a40;
  overflow: hidden;
  font-size: 0.9rem;
  height: 2.7rem;
  line-height: 2.5rem;
  padding-left: 0.5rem;
  background: #f5f5f5;
  background: #102530;
  color: #dee2e6;
}

.home .h1.fp--h1{
  margin-top: 4rem;
  font-weight: 200;
  line-height: 2.5rem;
}

.home .fp--h2{
  line-height: 1.7rem;
}

.home .fp--button a{
  font-weight: 700;
}


.home .fp--ex1--container--footer{
  margin-top:0px;
  float:left;
  width: 100%;  
  height: 50px;
}
.home .fp--ex1--container--img{
  height: 40px;
  width: 40px;
  margin: 0.25rem;
  border-radius: 50%;
  border: 1px solid #1f1f1f;
  background: #eee;
  background-size: cover;
  float:left;
  margin-left: 15px;
}
.home .fp--ex1--container--details{
  margin-left: 5px;
}
.home .fp--ex1--container--name{
  height: 25px;
  color:black;
  font-size: 12px;
  font-weight: 400;
  line-height:30px;
}
.home .fp--ex1--container--author{
  color:black;
  font-weight: 400;
  font-size: 10px;
  height: 15px;
  line-height:15px;
}

.home .fp--ex1--container--author span.username{
  color: #555;
}


[data-dark-mode] body .novem-jumbo  .novem-logo .lit{
  stroke: #8ed6fb;
}

@media only screen and (max-width: 600px) {
  .novem-jumbo  .novem-logo {
    left: 6.5rem ;
  }
}


@media only screen and (min-width: 1400px) {
  .home .h1 {
    font-size: calc(4.0rem );
  }
  .home .lead{
    opacity: 1.00;
    font-size: 2.0rem;
    line-height: 2.2rem;
    font-weight: 300;
  }

  .home .productlead.mb-5 {
    margin-bottom: 8rem !important;
  }

  .novem-shade{
    min-height:700px !important;
    height:700px !important;
  }
  .novem-bars{
    min-height:700px !important;
    height:700px !important;
    margin-top:-100px;
  }

  .home .h1.fp--h1{
    margin-top: 2rem;
    font-weight: 200;
    line-height: 4.5rem;
  }

  .fp--browser {
    position:absolute;
    right: 1rem;
    height: 500px;
    width: 800px;
    top: -60px;
  }

}


[data-dark-mode] body.home .fp--browser {
  /*
  #536670
  */

  background: linear-gradient(HSLA(0,0%,100%,.4),HSLA(0,0%,100%,.3) 25%,RGBA(246,249,252,.3) 50%,#536670 60%);
  box-shadow: inset 0 1px 1px 0 HSL(0deg  0% 100% / 10%), 0 50px 100px -20px RGB(50 50 93 / 25%), 0 30px 60px -30px RGB(0 0 0 / 30%);
}



[data-dark-mode] body.home .fp--ex1--container--name,,
[data-dark-mode] body.home .fp--ex1--container--author{
  color: #dee2e6
}

[data-dark-mode] body.home .fp--ex1--url,
[data-dark-mode] body.home .fp--ex1--url--nav,
[data-dark-mode] body.home .fp--ex1--container,
[data-dark-mode] body.home .fp--ex1--container text{
  background:#212121;
  color: #dee2e6
}

/* new frontpage */

body .obtn:hover ,
body .novem--vis .obtn:hover {
  background: #0184a7;
  color:#f0f0f0;
}


body .obtn ,
body .novem--vis .obtn {
  box-sizing:border-box;
  width: 100px;
  color:#f0f0f0;
  background: black;
  border-radius: 3.2px;
  display:block;
  font-size:0.6rem;
  font-weight:400;
  height:1.3rem;
  line-height:1.3rem;
  text-align:center;
  text-size-adjust:100%;
  text-decoration: none;
  margin-top: 10px;
  margin-left: 25px;
}
