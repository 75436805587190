/** Search form */
.search-form {
  @extend .form-inline !optional;
}

.search-form label {
  @extend .form-group !optional;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
