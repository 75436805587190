
// List level styles
.cd-morph-dropdown {
  .bg-layer {
  }

  .content .split{
    /*
    border-top: 1px solid #0184a7;
    background: #d8e4f1;

    background: #bedae4;
    background: $blue-100;
    background: #daeef5;
    */

    border-top: 1px solid #0184a7;
    background: #d8e4f1;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    color: #568798;

  }

  .dd-hdr{
    font-weight: 600;
  }

	.dropdown-list {
    filter: drop-shadow(0 5px 12px #ddd);

		&::before {
      filter: drop-shadow(0 0px 12px #ddd);
		}
	}

  .dropdown-list .content{
  }
  .dropdown{
  }

	#Products .content{
		width: 440px;
	}


  .content {

    padding: 1.5em;
    background: $white;
    padding-bottom: 0px !important;
  }

  .dd-header{
    font-weight: 400;

  }
  .dd-icon{
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 2px;
  }
  .dd-desc{
    //font-size: $font-size-base * 0.89;
    font-size: $font-size-base * 0.95;
    color: $gray-600;
  }

	.dropdown {
		a:hover div{
      color: #0184a7;
		}
		a {
			text-decoration: none;
			color:inherit;
		}
	}
}


.products .cd-morph-dropdown ,
[data-dark-mode] .cd-morph-dropdown {
	color: $body-color-dark;

  .bg-layer {
		background: $body-bg-dark;
	}
  .content {
		background: $body-bg-dark;
  }
  .dd-desc{
    color: $gray-600;
	}

	.dropdown {
		a:hover div{
			background: $body-bg-dark;
			color: #8ed6fb;
		}
	}

  .content .split{
    border-top: 1px solid #343a40;
    background: #102530;

  }


	.dropdown-list {
    //filter: drop-shadow(0 0 0.8rem #666);
    filter: drop-shadow(0 5px 12px #2f2f2f);

		&::before {
			border-bottom-color: $body-bg-dark;
      filter: drop-shadow(0 0 12px #2f2f2f);
		}
	}
}




.cd-morph-dropdown {
  #Documentation {

    .content{
      width: 400px;
    }

    .novem-docs >div{
      break-inside:avoid;
    }

    .novem-docs{
      column-count: 4;

      .novem-docs-cat-h{
        font-size: $font-size-base * 0.95;
        color: $gray-600;

        a:hover {
	        color: $body-color;
          filter: drop-shadow(0 0 1.5rem #8ed6fb);
        }
        a:hover div{
          background: inherit;
        }

        a>div.menu-draft{
          color: #ff0707;
        }

      }

    }

	}
}


.cd-morph-dropdown {
  #Company {
    .content{
      width: 240px;
      padding-bottom: 1.5rem !important;
    }
  }
}
