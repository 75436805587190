$OpenSansPath: "fonts/vendor/opensans" !default;

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* END Light */

/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src:url('#{$OpenSansPath}/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* END Light Italic */

/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* END Regular */

/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* END Italic */

/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* END Semibold */

/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* END Semibold Italic */

/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* END Bold */

/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* END Bold Italic */

/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* END Extrabold */

/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* END Extrabold Italic */
