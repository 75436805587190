.navbar .btn-link {
  color: $navbar-light-color;
  padding: 0.4375rem 0;
}

#mode {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-right: -0.25rem;
  padding-top: 0.52rem;
}

.btn-link:focus {
  outline: 0;
  box-shadow: none;
}

#navigation {
  margin-left: 1.25rem;
}

@include media-breakpoint-up(md) {
  #mode {
    margin-left: 0.325rem;
    margin-right: -0.375rem;
  }

  .navbar .btn-link {
    padding: 0.5625em 0.25rem 0.5rem 0.125rem;
  }
}

.navbar .btn-link:hover {
  color: $navbar-light-hover-color;
}

.navbar .btn-link:active {
  color: $navbar-light-active-color;
}

body .toggle-dark {
  display: block;
}

body .toggle-light {
  display: none;
}

[data-dark-mode] body .toggle-light {
  display: block;
}

[data-dark-mode] body .toggle-dark {
  display: none;
}

pre {
  position: relative;
}

@include media-breakpoint-down(md) {
  .btn-copy {
    display: none;
  }
}

.btn-copy {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  z-index: 10;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  padding: 0.25rem 0.5rem;
  color: $color-btn-text;
  background-color: $color-btn-bg;
  border-color: $color-btn-border;
}

.btn-copy:hover {
  color: $color-btn-text;
  background-color: lighten($color-btn-bg, 5%);
  border-color: lighten($color-btn-border, 15%);
}

.btn-copy:focus {
  color: $color-btn-text;
  background-color: $color-btn-bg;
  border-color: lighten($color-btn-border, 15%);
  box-shadow: none;
}

.btn-copy:active,
.btn-copy.active {
  color: $color-btn-text;
  background-color: $color-btn-bg;
  border-color: lighten($color-btn-border, 15%);
}

.btn-copy:active:focus,
.btn-copy.active:focus {
  box-shadow: none;
}

@include media-breakpoint-up(md) {
  pre:hover .btn-copy {
    opacity: 1;
  }
}

.btn-copy::after {
  content: "Copy";
  display: block;
  color: $color-btn-text;
}

.btn-copy:hover::after {
  content: "Copy";
  display: block;
  color: $color-btn-text;
}

.btn-copy:focus::after,
.btn-copy:active::after {
  content: "Copied";
  display: block;
  color: $color-btn-text;
}

.collapsible-sidebar {
  margin: 2.125rem 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0;
  font-weight: $headings-font-weight;
  font-size: $font-size-base;
  text-transform: uppercase;
  color: $body-color;
  background-color: transparent;
  border: 0;
}

div>ul>li>button.btn-toggle{
  text-transform: none;
}

.btn-toggle:hover,
.btn-toggle:focus {
  color: $body-color;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%2829, 45, 53, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  margin-bottom: 0.125rem;
}

.btn-toggle[aria-expanded="true"] {
  color: $body-color;
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: transparent;
  color: $link-color;
}

.btn-toggle-nav a.active {
  color: $link-color;
}

.doks-navbar .dropdown-menu,
.doks-subnavbar .dropdown-menu {
  font-size: 0.875rem;
}

.doks-navbar .dropdown-item.current,
.doks-subnavbar .dropdown-item.current {
  font-weight: 600;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem top 0.6rem;
  background-size: 0.75rem 0.75rem;
}

.btn-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-size: 1.5rem;
}

.offcanvas-header .btn-close {
  margin-right: 0 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-caret {
  margin-left: -0.1875rem;
  margin-right: -0.3125rem;
}

@media only screen and (min-width: 1400px) {
  .btn-toggle {
    font-size: $font-size-xl;
  }
}
