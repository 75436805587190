/** Theme variables */

// Source: https://material.io/design/color/dark-theme.html

$body-bg-dark: $gray-900;
$body-overlay-dark: darken($body-bg-dark, 2.5%);

/*
$border-dark: darken($body-bg-dark, 2.5%);
*/
$border-dark: $gray-800;
$body-color-dark: $gray-300;
$dots-dark: darken($body-color-dark, 50%);

$link-color-dark: $blue-300;
$button-color-dark: $link-color-dark;
$focus-color-dark: lighten($link-color-dark, 2.5%);

$navbar-dark-color:                 $body-color-dark;
$navbar-dark-hover-color:           $link-color-dark;
$navbar-dark-active-color:          $link-color-dark;

/** Theme styles */

body.products .navbar{
  background-color: transparent;
  border-bottom: transparent;
}

body.products .navbar-light .navbar-brand {
  color: $navbar-dark-color !important;
}

body.products .offcanvas {
  background-color: transparent;
}


body.products .navbar-light .navbar-nav .nav-link {
  color: $navbar-dark-color;
}

body.products .nav-tabs .nav-link.active {
  background: $body-bg-dark !important;
  border-color: $border-dark $border-dark $body-bg-dark;
  color: $body-color-dark;
}

body.products .nav-tabs .nav-link:hover {
  border-color: $border-dark $border-dark $border-dark;
  color: $body-color-dark;
}

body.products .offcanvas .nav-link {
  color: $body-color-dark;
}

body.products .offcanvas .nav-link:hover,
body.products .offcanvas .nav-link:focus {
  color: $link-color-dark;
}

body.products .offcanvas .nav-link.active {
  color: $link-color-dark;
}

body.products .navbar .btn-link {
  color: $navbar-dark-color;
}

body.products section.productlead {
  color: $body-color-dark;
}

body.products section img,
body.products section svg{
  max-height: 300px;
}

// charts graphic

//flowers
body.products section svg #elgb2jxj411yk,
body.products section svg #ell2yi4ugkou,
body.products section svg #el2eqmyi0qec5,
body.products section svg #elypxndpk2wck,
body.products section svg #elwzxm3h70i3j,
body.products section svg #elbcrj9q4as8 {
  fill: #263238 !important;
}

[data-dark-mode] body.products section svg #elgb2jxj411yk,
[data-dark-mode] body.products section svg #ell2yi4ugkou,
[data-dark-mode] body.products section svg #el2eqmyi0qec5,
[data-dark-mode] body.products section svg #elypxndpk2wck,
[data-dark-mode] body.products section svg #elwzxm3h70i3j,
[data-dark-mode] body.products section svg #elbcrj9q4as8 {
  fill: $gray-300 !important;
}

[data-dark-mode] #freepik--Floor--inject-2 >line{
  stroke: $gray-300 !important;
  //stroke : #455861 !important;
}

[data-dark-mode] #elqqnewup283b,
[data-dark-mode] #elik86qejxzb9{
  fill: #102530 !important;
  opacity: 1.0 !important;
}

[data-dark-mode] #el6rju534z6d5,
[data-dark-mode] #elcuogacngfro{
  fill:#455861 !important;
}


// bg document
[data-dark-mode] #elfkcl4h1mdkk{
  fill: #212529 !important;
  opacity: 1.0 !important;
}

[data-dark-mode] #el9koksho8iiu{
}

[data-dark-mode] #elwpujccjl9ua >g>rect,
[data-dark-mode] #elvdvwj8bf7p >g>rect{
  stroke: $gray-300 !important;
}

[data-dark-mode] #el0tovzj3y6p28,
[data-dark-mode] #el47arpydp3bn{
  fill: #455861 !important;
}

[data-dark-mode] #freepik--Line--inject-2 >line {
  stroke: $gray-300 !important;
}

// mail

[data-dark-mode] #freepik--background-simple--inject-2 >path{
  fill: #102530 !important;
  opacity: 1.0 !important;
}

[data-dark-mode] #freepik--Plant--inject-2 >path{
  stroke: $gray-700 !important;
}

[data-dark-mode] #freepik--Office--inject-2 >polyline,
[data-dark-mode] #freepik--Office--inject-2 >circle,
[data-dark-mode] #freepik--Office--inject-2 >rect,
[data-dark-mode] #freepik--Office--inject-2 >line,
[data-dark-mode] #freepik--Office--inject-2 >path{
  stroke: $gray-700 !important;
}

// reporting

[data-dark-mode] #el9z837ctranm ,
[data-dark-mode] #el9z837ctranm  > path{
  fill: #212529 !important;
  opacity: 1.0 !important;
}

[data-dark-mode] #elynegjwjyqlt{
  fill: #212529 !important;
}

[data-dark-mode] #elwtxnornigaj{

  fill: $gray-300 !important;
}


[data-dark-mode] #el80kdrrt56ue,
[data-dark-mode] #elnr8zfk6op6,
[data-dark-mode] #elxie3ixsgp2{
  fill: #455861 !important;
}


[data-dark-mode] #el5xisa8jh8kb,
[data-dark-mode] #elmajhwxthf4k{
  fill: $gray-300 !important;
}


[data-dark-mode] #el401oyi4x8ab > path {
  fill: #102530 !important;
  opacity: 1.0 !important;
}


[data-dark-mode] #ele3c4a2oqot{
  fill: #455861 !important;
}

[data-dark-mode] #elcjs4z5zzoyf > path,
[data-dark-mode] #elfqxpae95qod > path,
[data-dark-mode] #el3g2wlwjwpcc > path{
  fill: $gray-300 !important;
  opacity: 0.15 !important;
}

[data-dark-mode] #elca0wocva5i{
  //fill: $gray-700 !important;
}

[data-dark-mode] #elwlm18csj0y > rect{
  opacity: 0.15 !important;

}


// security
[data-dark-mode] #asdakdjasd91321314{
  fill: #212529 !important;
}
#asdakdjasd91321314{
  fill: $white !important;
}

[data-dark-mode] #asdq412sf5123{
  fill: #102530 !important;
  stroke: #102530 !important;
}

#asdq412sf5123{
  stroke: $white !important;
  fill: #bedae4 !important;
}



[data-dark-mode] #asvgg123--dash{
  stroke: $gray-700 !important;
}

[data-dark-mode] #asd124-chat,
[data-dark-mode] #asd124-chat-cor,
[data-dark-mode] #asdasd1-enve,
[data-dark-mode] #aq4123-globe,
[data-dark-mode] #af213--folderfront,
[data-dark-mode] #af213--folderback,
[data-dark-mode] #af213--cardback,
[data-dark-mode] #aq4123-wagon{
  fill: $gray-400 !important;
}


#asa123-lock-b,
#asa123-lock{
  fill: $white !important;
}

[data-dark-mode] #docasad123-hand,
[data-dark-mode] #asdd2151-hand,
[data-dark-mode] #asd51123-hand2{
  fill: #212529 !important;
}

[data-dark-mode] #freepik--background-simple--inject-2 >path#doc123-1{
  fill: #212529 !important;
}
#doc123-1{
  fill: $white !important;
}

[data-dark-mode] #freepik--background-simple--inject-2 >path#doc123-2,
[data-dark-mode] #freepik--background-simple--inject-2 >path#doc123-3{
  fill: lighten(#102530, 10%) !important;
}

[data-dark-mode] #docasd114-doc,
[data-dark-mode] #docasd-doc1{
  fill: $gray-300 !important;
}

#docasd114-doc,
#docasd-doc1,
#docasad123-hand,
#asdd2151-hand,
#asd51123-hand2{
  fill: $white !important;
}
