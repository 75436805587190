
.novem-skew-elem {
  background: #f5f5f5;
  padding-top:2rem;
  padding-bottom:2rem;
  margin-top:2rem;
  margin-bottom:2rem;
  border-top: 1px solid #bbc2c8;
  border-bottom: 1px solid #bbc2c8;
  transform:skewY(-4deg);
}

.novem-skew-elem.novem-blue{
  border-top: 1px solid $novem-bg-lb-b;
  border-bottom: 1px solid $novem-bg-lb-b;
  background: $novem-bg-lb;
}

.novem-skew-elem section{
  transform:skewY(4deg);
}

[data-dark-mode] body .novem-skew-elem {
  background: #102530;
  border-top: 1px solid #343a40;
  border-bottom: 1px solid #343a40;
}

@media only screen and (min-width: 1400px) {
  .novem-skew-elem {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
