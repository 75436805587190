.home .card,
.contributors.list .card,
.legal.list .card ,
.blog.list .card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: transform 0.3s;
}

.media .card{
  border: none;
}

/*
.home .card:hover,
.contributors.list .card:hover,
.blog.list .card:hover {
  transform: scale(1.025);
}
*/

.home .card-body,
.contributors.list .card-body,
.legal.list .card-body ,
.blog.list .card-body {
  padding: 0 2rem 1rem;
}

.blog-header {
  text-align: left;
  margin-bottom: 1.2rem;
}

.blog-footer {
  text-align: center;
}



/*
 * Novem blog styles
 **/

.blog .lead{

  margin-bottom: 2rem;

  font-size: 1.05rem;

  @include media-breakpoint-up(xxl) { 
    font-size: 1.1rem;

  }
}



.blog p{
  @include media-breakpoint-up(xxl) { 
    font-size: 1.00rem;
  }
}
.blog .content ul > li{
  @include media-breakpoint-up(xxl) { 
    font-size: 1.00rem;
  }
}


.blog .blogimg img{
  max-height: 400px;
}

.blog p.lead + 
div.blogimg{
  margin-top: -2rem;
}
