/** Theme variables */

// Source: https://material.io/design/color/dark-theme.html

$body-bg-dark: $gray-900;
$body-overlay-dark: darken($body-bg-dark, 2.5%);

/*
$border-dark: darken($body-bg-dark, 2.5%);
*/
$border-dark: $gray-800;
$body-color-dark: $black;
$body-color-light: $gray-300;
$dots-dark: darken($body-color-dark, 50%);

$link-color-dark: $blue-300;
$button-color-dark: $link-color-dark;
$focus-color-dark: lighten($link-color-dark, 2.5%);

$navbar-dark-color:                 $body-color-dark;
$navbar-dark-hover-color:           $link-color-dark;
$navbar-dark-active-color:          $link-color-dark;

/** Theme styles */

body.home .navbar{
  background-color: transparent;
  border-bottom: transparent;
}

body.home .navbar-light .navbar-brand {
  color: $navbar-dark-color !important;
}

[data-dark-mode] body.home .navbar-light .navbar-brand {
  color: $body-color-light !important;
}

body.home .offcanvas {
  background-color: transparent;
}


body.home .navbar-light .navbar-nav .nav-link {
  color: $navbar-dark-color;
}

body.home .nav-tabs .nav-link.active {
  background: $body-bg-dark !important;
  border-color: $border-dark $border-dark $body-bg-dark;
  color: $body-color-dark;
}

body.home .nav-tabs .nav-link:hover {
  border-color: $border-dark $border-dark $border-dark;
  color: $body-color-dark;
}

body.home .offcanvas .nav-link {
  color: $body-color-dark;
}

[data-dark-mode] body.home .offcanvas .nav-link {
  color: $body-color-light;
}


body.home .offcanvas .nav-link:hover,
body.home .offcanvas .nav-link:focus {
  color: $link-color-dark;
}

body.home .offcanvas .nav-link.active {
  color: $link-color-dark;
}

body.home .navbar .btn-menu,
body.home .navbar .btn-link{
  color: $navbar-dark-color;
}

[data-dark-mode] body.home .navbar .btn-menu,
[data-dark-mode] body.home .navbar .btn-link{
  color: $body-color-light;
}

body.home section.productlead {
  color: $body-color-dark;
}
[data-dark-mode] body.home section.productlead {
  color: $body-color-light;
}

@include media-breakpoint-down(md) { 
  body.home .offcanvas {
    background: white;
  }
  body.home .offcanvas .nav-link {
    color: $body-color;
    font-size: $font-size-base * 1.3;
  }
  body.home .offcanvas .nav-link:hover,
  body.home .offcanvas .nav-link:focus {
    color: $link-color;
  }
}

