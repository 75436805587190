
/*
 * Media control overrides
 *
 * This document contains a TON of overrides to make the page look
 * good across all the various sizes we've desinged for.
 *
 * We care about: 
 *  * iphone portrait         390
 *  * iphone landscape        844
 *  * ipad mini portrait      764
 *  * ipad mini landscape    1024
 *  * ipad air portrait       820
 *  * ipad air landscape     1180
 *  
 *  * Mac 13"                1280
 *  * Mac 14" 
 * 
 **/

// small phones
@media only screen and (min-width: 0px) { 

}

// larger phones
@media only screen and (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) { 

}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) { 

}
/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  @supports (position: -webkit-sticky) or (position: sticky){
    .docs-sidebar {
      top: 2.8rem;
      height: calc(100vh - 2.8rem);
    }
  }

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
}

/* XXX-Large devices (larger desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {

  .docs .breadcrumb {
    font-size:0.9rem;
  }

  .lead {
    font-size: 1.0rem;
  }

  .cd-morph-dropdown #Documentation .novem-docs {
    .novem-docs-cat-t {
      font-size: 1.00rem;
    }
    .novem-docs-cat-h {
      font-size: 0.85rem;
    }
  }

  .cd-morph-dropdown .dd-desc {
    font-size: 0.85rem;
  }

  .morph-dropdown-wrapper{

    #Company {
      .content{
        width: 320px;
      }
    }
    #Documentation{
      .content{
        width: 550px;

      }
    }
    #Products {
      .content{
        width: 550px;
      }
    }
  }

}






