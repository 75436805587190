
.slicer-demo {
  max-width: 538px; 
  height: 268px; 
  border: none; 
  overflow: hidden;
  margin:auto;
  border: none;
}

.slicer-demo > thead {
  border: none;
}

.slicer-demo > tbody > tr > td{
    line-height: 16px;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    margin: 0;
    padding:0px;
}

.slicer-demo > tbody > tr > td{
    border: 1px solid #cbd5e0 !important;
}

html[data-dark-mode] .slicer-demo > tbody > tr > td{
    border: 1px solid #718096 !important;
}


.slicer-controls .slicer-label{
  font-size: $font-size-md;
}

.slicer-controls input{
  resize: none;
  background: #f5f5f5;

  border: 1px solid #adb5bd;
  background: #f5f5f5;
  color: #1d2d35;
  line-height:30px;
  height:30px;
  padding-left: 5px;
  padding-right: 5px;

  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
}

html[data-dark-mode] .slicer-controls input{
  background: #1b1f22;
  color: #dee2e6;
}

html .slicer-demo > tbody > tr > td.sliced{
  background:#d32e9d;
}
html[data-dark-mode] .slicer-demo > tbody > tr > td.sliced{
  background:#8ed6fb;
}
