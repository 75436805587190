.h-full {
  height:100vh;
}

.chat--input {
  position: fixed;
  bottom:0px;
  left:0px;
  width:100%;

  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 47.73%);
}

.chat--input .chat--hold {
  box-shadow:rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
  border-radius: 0.375rem;
  padding-right: 0px!important;
}



.chat--input textarea{
  max-height: 192px;
  height: 24px;
  overflow-y: hidden;
  resize: none;
  outline: none;

  overflow-wrap:break-word;
  overflow-y: hidden;
  line-height:24px;

  padding-right: 1.7rem !important;


  //padding-left:1rem;
  //padding-top:0.75rem;
  //padding-bottom:0.75rem;

  width:100%;
}
.chat--input button{
  opacity:0.4;
  position:absolute;
  bottom:0.625rem;
  right:0.625rem;
  right:0px;
}

.relative {
  position:relative;
}

.chat-item .novem--vis .holder{
  min-height:300px;
}

.chat-item.minerva{
  background-color: #fafafa;
}

.chat-item{
  border-color: rgba(0,0,0,.1);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  border-bottom-width: 1px;
  border-style: solid;
}

.chat-item li>p{
  margin-top:0px;
  margin-bottom:0px;
}
.chat-item li>ul{
  margin-bottom: 10px;
}


.chat-server{
  background-color: #f5f5f5;
}
.chat-server.error{
  background-color: #fef5f5;

  .chat-avatar .chat-server circle{
    stroke: #af1f1f;
  }
}

.chat-img{
}

.outer-chart{
  padding-bottom: 160px;
}

.chat-avatar {
  width: 80px;
  height:80px;
}
.chat-avatar img{
  border-radius:50%;
  border: 1px solid #1f1f1f;
}
.chat-avatar .chat-server circle{
  fill: none;
  stroke: #1f1f1f;
}

.chat-avatar .chat-server {
  border-radius: 50%;
}



::-webkit-scrollbar {
    height: 1rem;
    width: .5rem
}

::-webkit-scrollbar:horizontal {
    height: .5rem;
    width: 1rem
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px
}

::-webkit-scrollbar-thumb {
    --tw-border-opacity: 1;
    background-color: rgba(217,217,217,.8);
    border-color: rgba(255,255,255,var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px
}

::-webkit-scrollbar-thumb:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236,236,236,var(--tw-bg-opacity))
}

[data-dark-mode] ::-webkit-scrollbar-thumb {
    --tw-bg-opacity: 1;
    background-color: rgba(88,88,88,var(--tw-bg-opacity))
}

[data-dark-mode] ::-webkit-scrollbar-thumb:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172,172,172,var(--tw-bg-opacity))
}


.chat-action-list{
  width: 80px;
  margin-top: 2px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;


  &>div{
    width: 10px;
    height:10px;
    margin:2px;
  }

  &>div:hover{
    //cursor:pointer;
    //width: 14px;
  }

  .ca-p1-init{
    background: #56e7c3;
  }
  .ca-p1-complete{
    background: rgb(16, 163, 127);
  }
  .ca-p2-init{
    background: #e69aff;
  }
  .ca-p2-complete{
    background: #ca5eed;
  }
}

.chat-item code.hljs {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem !important;
  background: #efefef;
}

.text-xs {
  font-size: 0.8rem;
}

[data-dark-mode] .chat-avatar .chat-server circle{
  stroke: #dee2e6;
  stroke: #60676e;
  stroke: #343a40;
  stroke: #4e5760;
}
[data-dark-mode] .chat--input .chat--hold {
  box-shadow:rgba(33, 37, 41, 0) 0px 0px 0px 0px, rgba(33, 37, 41, 0) 0px 0px 0px 0px, rgba(52,58,64, 0.55) 0px 0px 10px 0px;

  background-color: #212529 !important;
  color: #dee2e6 !important;
  border-color: #343a40 !important;
}
[data-dark-mode] .chat--input .chat--hold svg{
  color: #dee2e6 !important;
}
[data-dark-mode] .chat--input .chat--hold textarea{
  color: #dee2e6 !important;
}
[data-dark-mode] .chat--input {
  background-image: linear-gradient(180deg, hsla(0,0%,100%,0) 13.94%, #212529 47.73%);
}
[data-dark-mode] .chat-server{
  background-color: #1b1f22;
}
[data-dark-mode] .chat-avatar img{
  border: 1px solid #343a40;
}
[data-dark-mode] .chat-item {
  border-color: #343a40;
}

[data-dark-mode] .chat-item.minerva{
  background-color: #1f1f20;
  background-color: #131518;
  background-color: #1d1f22;
}

[data-dark-mode] .chat-item code.hljs {
  border-color: #343a40;
  background-color: #1d1f22;
}
[data-dark-mode] .chat-server.error{
  background-color: #221b1f;

  .chat-avatar .chat-server circle{
    stroke: #893d3d;
  }
}





#tsparticles{
  width: 100%;
  height:440px;
}
