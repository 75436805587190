.alert {
  font-family: $font-family-monospace;
  font-size: $font-size-sm;
}

.alert-icon {
  margin-right: 0.75rem;
}

.docs main .alert {
  margin: 1rem -1.5rem;
}

.alert p:last-child{
  margin-bottom: 0px;
}

.alert .alert-link {
  text-decoration: underline;
}

.alert-dark {
  color: $white;
  background-color: $black;
}

.alert-dark .alert-link {
  color: $white;
}

.alert-light {
  color: $black;
}

.alert-warning {
  background: $beige;
  color: $black;
  border-left: 4px solid #ffc107;
}

/*
.alert-light {
  color: #215888;
  background: linear-gradient(-45deg, rgb(212, 245, 255), rgb(234, 250, 255), rgb(234, 250, 255), #d3f6ef);
}

.alert-light .alert-link {
  color: #215888;
}
*/

.alert-white {
  background-color: rgba(255, 255, 255, 0.95);
}

.alert-primary {
  color: $white;
  background-color: $primary;
}

.alert-primary .alert-link {
  color: $white;
}

.alert .alert-link:hover,
.alert .alert-link:focus {
  text-decoration: none;
}

.alert-dismissible .btn-close {
  position: absolute;

  /*
  top: 50%;
  transform: translateY(-50%);
  */
  top: 0.75rem;
  right: 1rem;
  z-index: 2;
  padding: 0.625rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-size: 1.5rem;
  filter: invert(1) grayscale(100%) brightness(200%);
}

/* only used for drafts */
.docs main div.docs-draft-notice{
  margin: 1rem -1.5rem;
}

.legal div.docs-draft-notice,
.blog div.docs-draft-notice{
  margin-bottom: 1rem;
}
div.docs-draft-notice{
  font-family: $font-family-monospace;
  font-size: $font-size-sm;
  background: $beige;
  background: #fbf0f0;
  color: $black;
  border-left: 4px solid #ff0707;
  padding: 0.750rem;
}


[data-dark-mode] body div.docs-draft-notice{
  color: $body-color-dark;
  background: darken(#ff0707,43%);
  border-left: 4px solid darken(#ff0707, 30%);
}

@include media-breakpoint-up(md) {
  .alert-dismissible .btn-close {
    background-size: 1.25rem;
  }
}

.alert code {
  background: darken($beige, 5%);
  color: $black;
  padding: 0.25rem 0.5rem;
}

@media only screen and (min-width: 1400px) {
   div.docs-draft-notice,
  .alert {
    font-size: $font-size-md;
  }
}
