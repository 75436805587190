.comment-list {
  @extend .list-unstyled;
}

.comment-list ol {
  list-style: none;
}

.comment-form p {
  @extend .form-group !optional;
}

.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}

.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-secondary;
}

blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 3px solid $gray-300;
  padding-left: 1rem;
}
