
.novempath .np--type{
  color: $white;
  font-weight: 600;
  line-height: $font-size-lg;
  font-size: $font-size-lg;
}

.novempath .np--type.np--style-conf{
  background: $novem-pink;
}

.novempath .np--type.np--style-data{
  background: $green;

  background: $novem-blue;
}

.novempath .np--type.np--style-md{
  background: $blue;
}

@include media-breakpoint-up(lg) {
  .novempath .np--top.row,
  .novempath .np--inner.row {
    margin-left:0px;
    margin-right: 0px;
  }
}

.novempath .np--top{
  border-bottom: 1px solid $gray-500;
}

.novempath .np--top > div{
  padding-top:    5px;
  padding-bottom: 5px;
}

.novempath .np--path{
  background: $lightgray;
  color: $novemclr;
  line-height: $font-size-lg;
  font-size: $font-size-lg;
}

.novempath .np--inner.row > pre{
  margin:0px;
  width: 100%;
  padding-left:0px;
  padding-right:0px;
}
.novempath .np--inner.row > pre > code{
  border-radius: 0px;
  padding:1rem;
}



[data-dark-mode] .novempath .np--path{
  background:#1b1f22;
  color: $gray-300;
}



