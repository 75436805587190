

figure.mtable {
  border-left: 5px solid $novemclr;
  padding-top:15px;
  padding-bottom: 15px;
  margin: 0 0 1rem;
}
figure.mtable.disp {
  border-left: 0px solid $novemclr;
}

[data-dark-mode] figure.mtable {
  border-color: $gray-500;
}

@include media-breakpoint-up(lg) {
  figure.mtable {
  padding-bottom: 0px;
  }
}
