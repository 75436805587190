
.login.page .card{
  position:relative;
  border-color: #212529 !important;
  background-color: #212529;
  color: #dee2e6;

  box-shadow: 0px 5px 35px rgba(0,0,0,0.289);
}
.login.page .card .alert{
  border-radius: 5px;
  border: 2px solid #a71726;
  
}


.form-control {
  font-size: 1rem;
}


.login.page .card .form-control{
  background-color: #212529;
  color: #dee2e6;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: #dee2e6 !important;
}

.form-floating > .form-control:focus ~ label::after{
  background-color: #212529 !important;
  border-radius:0px !important;
  color: #dee2e6 !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after{
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #212529 !important;
  border-radius:0px !important;
  color: #dee2e6 !important;
  /*border-radius: var(--bs-border-radius);*/
}
