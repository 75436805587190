.anim-background {
  width: 100vw;
  height: 100vh;
  position:absolute;
  left:0px;
  top:0px;
  margin:0px;
  padding:0px;

  background: linear-gradient(120deg, #1f1f1f, #0184a7 50%, #ca5eed);
  z-index: -100;
}
