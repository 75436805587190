
.pricing .pricebox .h2,
.pricing .pricebox .h3,
.pricing .pricebox .h4,
.pricing .pricebox .h5{
  color: $body-color-light;
}
.pricing .pricebox.new .h1 {
  font-size: 50px;
  font-weight: 200;
}
.pricing .pricebox.new .h1 >span{
  font-size: 30px;
  margin-left: -10px;
  font-weight: 100;
}
.pricing .pricebox.new.ent .h1 {
  font-size: 40px;
}

.pricing .pricebox.new table tr:nth-of-type(2) td{
  border-width: 0px;
}


body.pricing .pricebox.new .card{
  background: #f6f6f6;
  font-size: 13px;
}
body.pricing .pricebox.new .card table.st{
  text-align:center;
  font-size: 10px;
}

[data-dark-mode] body.pricing .pricebox.new .card{
  background: $body-color-dark;
  color:$body-color-light;
}

[data-dark-mode] body.pricing .card .list-group-flush > .list-group-item,
[data-dark-mode] body.pricing .card{
  //border-color: $body-color-light;
  background: $body-color-dark;
  color:$body-color-light;
}
