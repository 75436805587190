.footer {
  border-top: 1px solid $gray-600;
  border-top: 1px solid #0184a7;
  border-top: 1px solid #bbc2c8;
  //background: #212529;
  //background: #f6f9fc;
  //background:#d9e2ec;
  //background: #dae4ee;
  background: $novem-bg-lb;
  background: #d8d8d8;
  background: $gray-300;
  background: #d8e4f1;
  background: #f5f5f5;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.footer .bgblock{
  overflow:hidden;
}

.footer h6{
  color: $gray-700;
  font-weight: 400;

}

.footer ul {
  margin-bottom: 0;
  color: $gray-400;
  font-weight: 400;

}

.footer li {
  font-size: $font-size-sm;
  margin-bottom: 0;
}

.footer li .nav-link{
  color: $body-color;
}

[data-dark-mode] .footer li .nav-link{
  color: $body-color-dark;
}


.footer li .nav-link{
  font-size: $font-size-base * 1.1;
}

@media only screen and (min-width: 1200px) {

  .footer li .nav-link{
    font-size: $font-size-base * 1.3;
  }
}

@media only screen and (min-width: 1400px) {
  .footer li .nav-link{
    font-size: $font-size-base * 1.4;
  }
}

/*
.footer .novemlogo {
  max-height:30px;
}
*/

.footer .novemlogo {
  color: $body-color;
}

.footer .footerc{
  font-size: 0.8em;
}

[data-dark-mode] .footer .novemlogo {
  color: $body-color-dark;
}
