.contributors .content,
.blog .content,
.page .content,
.error404 .content,
.docs.list .content,
.tutorial.list .content,
.showcase.list .content {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.content img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 2rem 0 1rem;
}

.offcanvas-header {
  border-bottom: 1px solid $gray-300;
  padding-top: 1.0625rem;
  padding-bottom: 0.8125rem;
}

h5.offcanvas-title {
  margin: 0;
}

body.docs {
  padding-top: 0 !important;
}

@include media-breakpoint-up(md) {
  body {
    font-size: $font-size-md;

    /*
    padding-top: 4rem !important;
    */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0.800rem;
  }
}

.home h1 {
  /* font-size: calc(1.375rem + 1.5vw); */
  font-size: calc(1.875rem + 1.5vw);
  font-size: calc(1.1rem + 1.5vw);
  font-weight: 300;
}
.home .lead {
  font-weight: 300;
  font-size: 1.0rem;
  line-height: 1.2rem;
}

a:hover,
a:focus {
  text-decoration: underline;
}

a.nav-link:hover,
a.btn:hover,
a.btn:focus {
  text-decoration: none;
}

.section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.section-md {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*
.section svg {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-top;
}
*/

/*
body {
  padding-top: 3.5625rem;
}
*/
body {
  position:relative;
  z-index: -1000;
}

.docs-sidebar {
  order: 2;
}

@include media-breakpoint-up(lg) {
  .docs-sidebar {
    order: 0;
    border-right: 1px solid $gray-200;
  }

  @supports ((position:-webkit-sticky) or (position:sticky)) {
    .docs-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
  }
}

@include media-breakpoint-up(xl) {
  .docs-sidebar {
    flex: 0 1 320px;
  }
}

.docs-links {
  padding-bottom: 5rem;
}

@include media-breakpoint-up(lg) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .docs-links {
      max-height: calc(100vh - 4rem);
      overflow-y: scroll;
    }
  }
}

@include media-breakpoint-up(lg) {
  .docs-links {
    display: block;
    width: auto;
    margin-right: -1.5rem;
    padding-bottom: 4rem;
  }
}

.docs-toc {
  order: 2;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .docs-toc {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
}

// some hacks for low content pages
.legal.list .wrap>.content ,
.media.list .wrap>.content {
  min-height: calc(100vh - 250px - 50px + 4px);
}

.docs-content {
  padding-bottom: 3rem;
  order: 1;
}

.docs-navigation {
  border-top: 1px solid $gray-200;
  margin-top: 2rem;
  margin-bottom: 0;
  padding-top: 2rem;
}

.docs-navigation a {
  font-size: $font-size-base * 0.9;
}

@media only screen and (min-width: 1400px) {
  body{
    font-size: $font-size-xl;
  }
}
@include media-breakpoint-up(lg) {
  .docs-navigation {
    margin-bottom: -1rem;
  }

  .docs-navigation a {
    font-size: $font-size-base;
  }
}

.navbar a:hover,
.navbar a:focus {
  text-decoration: none;
}

#TableOfContents ul {
  padding-left: 0;
  list-style: none;
}

::selection {
  background: rgba(212, 53, 159, 0.2);
}

.bg-dots {
  background-image: radial-gradient($gray-300 15%, transparent 15%);
  background-position: 0 0;
  background-size: 1rem 1rem;
  -webkit-mask: linear-gradient(to top, #fff, transparent);
  mask: linear-gradient(to top, #fff, transparent);
  width: 100%;
  height: 9rem;
  margin-top: -10rem;
  z-index: -1;
}

.bg-dots-md {
  margin-top: -11rem;
}

.bg-dots-lg {
  margin-top: -12rem;
}

// https://fossheim.io/writing/posts/css-text-gradient/
.gradient-text {
  background-color: $primary;
  background-image: linear-gradient(90deg, $primary, $blue-300 50%, $pink-500);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.katex {
 // font-size: $font-size-md;
}

.card-bar {
  border-top: 4px solid;
  border-image-source: linear-gradient(90deg, $primary, #8ed6fb 50%, #d32e9d);
  border-image-slice: 1;
}

.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.show {
  opacity: 0.7;
}

@include media-breakpoint-up(md) {
  .modal-backdrop.show {
    opacity: 0;
  }
}
