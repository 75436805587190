
.tipbox {
  border: 1px solid rgba(0, 0, 0, 0.1);
  user-select: none;
  //font-size: 0.9rem;
}

.tipbox:hover {
  cursor: pointer;
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

[data-dark-mode] .tipbox {
  color: $body-color-dark;
}
[data-dark-mode] .tipbox:hover {
  background: #1f262e;
  border: 1px solid #576878;
}
