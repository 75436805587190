/*

Based on Ascetic by (c) Ivan Sagalaev <Maniac@SoftwareManiacs.Org>

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  //background: $beige;
  background: $lightgray;
  color: $body-color;
}

.hljs-string,
.hljs-template-variable,
.hljs-bullet,
.hljs-section,
.hljs-addition,
.hljs-attribute,
.hljs-link {
  color: $pink-500;
}

.hljs-number {
  //color: $blue;
  //color: #375f9a;
  color: $pink-500;

  color: darken($pink-500, 20%);
}

.hljs-variable{
  color: $pink-500;
}

.hljs-symbol {
  font-weight: bold;
}
.hljs-inst{
  color: #375f9a;
  font-weight: bold;
}

.hljs-comment,
.hljs-quote,
.hljs-meta,
.hljs-deletion {
  color: #888;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-section,
.hljs-name,
.hljs-type,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}

[data-dark-mode] body .hljs {
  background: $body-overlay-dark;
  color: $body-color-dark;
}

[data-dark-mode] body .hljs-string,
[data-dark-mode] body .hljs-template-variable,
[data-dark-mode] body .hljs-bullet,
[data-dark-mode] body .hljs-section,
[data-dark-mode] body .hljs-addition,
[data-dark-mode] body .hljs-attribute,
[data-dark-mode] body .hljs-link {
  color: $blue-300;
}

[data-dark-mode] body .hljs-symbol {
}

[data-dark-mode] body .hljs-inst{
  color: darken($red, 10%);
  color: #ff957f;
}

[data-dark-mode] body .hljs-number {
  color: darken($blue, 10%);
  color: #60a0ff;
}

[data-dark-mode] body .hljs-variable{
  color: $blue-300;
}
