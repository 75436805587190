$color-1: $yellow;
$color-2: $black;
$color-3: $white;
$color-4: $lightgray;
$color-5: $red;


@import 'variables'; // colors, fonts etc...
@import 'mixins'; // custom mixins
@import 'layout'; // responsive grid and media queries


.cd-morph-dropdown {

	&::before {
		/* never visible - used in JS to check mq */
		content: 'mobile';
		display: none;
	}

	.nav-trigger {
		/* menu icon - visible on small screens only */
		position: absolute;
		top: 0;
		right: 0;
		height: 60px;
		width: 60px;
		/* replace text with icon */
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		color: transparent;

		span, span::after, span::before {
			/* these are the 3 lines of the menu icon */
			position: absolute;
			background-color: $color-1;
			height: 3px;
			width: 26px;
		}

		span {
			@include center;
			transition: background-color .3s;
		}

		span::after, span::before {
			content: '';
			left: 0;
			transition: transform .3s;
		}

		span::before {
			transform: translateY(-9px);
		}

		span::after {
			transform: translateY(9px);
		} 
	}

	&.nav-open .nav-trigger span {
		background-color: transparent;

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}


	.morph-dropdown-wrapper {
		display: none;
		position: absolute;
		top: 60px;
		left: 0;
		width: 100%;
		padding: 1.2em 5%;
	}

	&.nav-open .morph-dropdown-wrapper {
		display: block;
	}

	.dropdown-list  > ul > li {
		margin-bottom: 3.3em;
	}

	.label {
		display: block;
		font-size: 2.2rem;
		color: $color-1;
		margin-bottom: .8em;
	}

	.content li {
		@include clearfix;
	}

	.gallery .content li {
		margin-bottom: 1.4em;
	}

	.gallery .content a {
		display: block;

		&::before {
        /* icon on the left */
        content: '';
        display: inline-block;
        float: left;
        height: 54px;
        width: 54px;
        margin-right: .6em;
        border-radius: 50%;
        transition: background .2s;
      }

      span, em {
        display: block;
        line-height: 1.2;
      }

      em {
        font-size: 1.8rem;
        padding: .4em 0 .2em;
        color: $color-1;
      }

      span {
        font-size: 1.4rem;
        color: shade($color-3, 35%);
      }

      &:hover::before {
			background-color: $color-1;
		}
	}


	.links .content > ul > li {
		margin-top: 1em;
	}

	.links-list a,
	.dd-btn {
		display: block;
		margin-left: 14px;
		font-size: 2.2rem;
		line-height: 1.6;

		&:hover {
			color: $color-1;
		}
	}

	.content h2 {
		color: shade($color-3, 35%);
		text-transform: uppercase;
		font-weight: bold;
		font-size: 1.3rem;
		margin: 20px 0 10px 14px;
	}

	@include MQ(L) {
		width: 100%;

		&::before {
			content: 'desktop';
		}

		.nav-trigger {
			display: none;
		}


		&.is-dropdown-visible .main-nav > ul > li > a {
			/* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
			opacity: .6;
		}

		&.is-dropdown-visible .main-nav > ul > li.active > a {
			opacity: 1;
		}

		.morph-dropdown-wrapper {
			/* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
			display: block;
			top: 58px;
			/* overwrite mobile style */
			width: auto;
			padding: 0;
			box-shadow: none;
			background-color: transparent;
			/* Force Hardware acceleration */
			transform: translateZ(0);
			will-change: transform;
			transform: translateY(20px);
			transition: transform .3s;
		}

		&.is-dropdown-visible .morph-dropdown-wrapper {
			transform: translateY(0);
		}

		.dropdown-list {
			position: absolute;
			top: 0;
			left: 0;
			visibility: hidden;
			transform: translateZ(0);
			will-change: transform, width, height;
			transition: visibility .3s;

			.no-csstransitions & {
				display: none;
			}

			&::before {
				/* dropdown top triangle */
				content: '';
				position: absolute;
				bottom: 100%;
				@include center(x);
				height: 0;
				width: 0;
				border: 8px solid transparent;
				border-bottom-color: $white;
				opacity: 0;
				transition: opacity .3s;
        filter: drop-shadow(0 0 0.8rem #ddd);
			}

			> ul {
				position: relative;
				z-index: 1;
				height: 100%;
				width: 100%;
				overflow: hidden;
			}
		}

		&.is-dropdown-visible .dropdown-list {
			visibility: visible;
			transition: transform .3s, width .3s, height .3s;

			&::before {
				opacity: 1;
			}
		}

		.dropdown:not(.usermenu) {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			transition: opacity .3s, visibility .3s;

			&.active {
				opacity: 1;
				visibility: visible;
			}

			&.move-left .content {
				transform: translateX(-100px);
			}

			&.move-right .content {
				transform: translateX(100px);
			}
		}


		.content {
			transition: transform .3s;
			text-align: left;

			> ul {
				@include clearfix;
			}

			> ul > li {
				@include column(.48);
				margin-right: 4%;
				margin-top: 0;

				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}

		.gallery .content {
			/* you need to set a width for the .content elements because they have a position absolute */
			width: 510px;

			li {
				margin-bottom: 1.8em;
			}
		}

		.links .content > ul > li {
			margin-top: 0;
		}

		.links .content,
		.button .content {
			width: 390px;
		}

		.links-list a {
			font-size: 1.6rem;
			margin-left: 0;
		}

		.dd-btn {
			display: block;
			width: 100%;
			height: 60px;
			margin: 1.5em 0 0;
			font-size: 1.8rem;
			text-align: center;
			color: $color-3;
			line-height: 60px;
			background: $color-2;
			@include font-smoothing;

			&:hover {
				background: $color-1;
				color: $color-3;
			}
		}

		.content h2 {
			font-size: 1.8rem;
			text-transform: none;
			font-weight: normal;
			color: $color-1;
			margin: 0 0 .6em;
		}

		.bg-layer {
			/* morph dropdown background */
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 1px;
			background: $white;
			opacity: 0;
			transition: opacity .3s;
			transform-origin: top left;
			transform: translateZ(0);
			will-change: transform;
			backface-visibility: hidden;
		}

		&.is-dropdown-visible .bg-layer {
			opacity: 1;
			transition: transform .3s, opacity .3s;
		}
	}
}


@import 'styles'; //override with novem menu styles
