pre,
code,
kbd,
samp {
  font-family: $font-family-monospace;
  font-size: $font-size-sm;
  border-radius: $border-radius;
}

code {
  background: $lightgray;
  color: $black;
  padding: 0.25rem 0.5rem;
}

pre {
  margin: 1rem 0;
}

pre code {
  display: block;
  overflow-x: auto;
  line-height: 1.3;
  padding: 1.25rem 1.5rem;
  tab-size: 4;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.hljs {
  padding: 1.5rem !important;
}

@include media-breakpoint-down(sm) {
  pre,
  code,
  kbd,
  samp {
    border-radius: 0;
  }

  pre {
    margin: 2rem -1.5rem;
  }
}

pre code::-webkit-scrollbar {
  height: 5px;
}

pre code::-webkit-scrollbar-thumb {
  background: $gray-400;
}

pre code:hover {
  scrollbar-width: thin;
  scrollbar-color: $gray-500 transparent;
}

pre code::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
}

code.language-mermaid {
  background: none;
}

@media only screen and (min-width: 1400px) {
  pre,
  code,
  kbd,
  samp {
    font-size: $font-size-md;
  }

  pre code {
    line-height:$font-size-md * 1.11;
  }
}

