$SourceSansProPath: "fonts/vendor/sourcesans" !default;

/* BEGIN Light */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* END Light */

/* BEGIN Light Italic */
@font-face {
  font-family: 'Source Sans Pro';
  src:url('#{$SourceSansProPath}/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* END Light Italic */

/* BEGIN Regular */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* END Regular */

/* BEGIN Italic */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* END Italic */

/* BEGIN Semibold */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* END Semibold */

/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* END Semibold Italic */

/* BEGIN Bold */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* END Bold */

/* BEGIN Bold Italic */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$SourceSansProPath}/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* END Bold Italic */

